export const LogoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="46"
      viewBox="0 0 76 46"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.9443 2.26878C30.6481 1.31331 25.6188 -0.779545 18.1744 0.303127C11.5005 1.27375 0.552632 5.30094 0 21.7878C0 34.9075 8.08865 42.1552 16.1606 44.5115C18.2322 45.1162 19.9895 45.3196 21.9144 45.2787V44.7992C21.8279 44.7884 21.7445 44.778 21.6637 44.7679C20.8874 44.6712 20.3632 44.6059 19.8698 44.5133C17.6671 44.0998 15.578 43.0301 14.1383 41.3124C11.1672 37.7678 9.3376 32.0718 8.39501 27.4277C7.65335 23.7735 7.70304 19.9999 8.18957 16.3031C8.70562 12.3821 9.97439 7.74454 14.4131 3.57842C16.7009 1.43111 19.7576 0.247286 22.8951 0.276699C26.7106 0.312469 31.5604 1.22744 34.9839 4.71441C35.8716 5.50062 36.9978 7.01593 37.3814 8.93386H37.8608L37.8332 0.0154706H37.3537C37.271 1.75345 36.4324 2.50408 34.9018 2.66625C34.3845 2.72105 33.8672 2.64627 33.3851 2.45081C33.2515 2.39665 33.1046 2.33549 32.9443 2.26878ZM40.3601 22.0717L27.6682 22.0717V22.5512C28.5648 22.5512 30.0125 22.5753 30.3869 24.5651V43.3608C33.2638 42.21 36.2366 39.9085 37.5791 37.9906V24.5651C37.8956 23.3026 39.1135 22.1677 40.3601 22.5512V22.0717Z"
        fill="#EFEFEF"
      />
      <path
        d="M44.0665 2.50875C44.0665 1.69823 42.8198 0.590821 41.2855 0.590821V0.0154378L62.7663 0.207235C66.3277 0.330042 72.7509 3.32946 73.0272 11.6189C73.2728 18.9874 66.3145 22.5511 63.5335 22.1566V22.5511C67.5486 23.5807 69.8755 26.3205 71.0705 28.6846C71.9279 30.3808 72.0165 32.3249 71.9904 34.2253L71.896 41.0789C71.8833 41.9994 72.1073 43.0068 72.9152 43.4482C73.9873 44.0339 75.0874 43.6014 75.8082 43.1689L76 43.4566C71.6662 47.7037 66.7342 44.4532 65.3044 41.4312C65.0183 40.8266 64.9723 40.1477 64.9738 39.4788C64.9798 36.9252 64.9812 32.2714 64.6613 28.8803C64.3878 25.9804 63.5285 24.8492 62.7116 23.8307C62.1735 23.1599 61.412 22.6728 60.5607 22.5511V22.1566C63.1396 22.1566 65.0666 21.7697 65.9508 13.2224C66.3145 4.52259 63.8564 1.48117 62.3828 0.590821H50.9711L50.971 42.5577C50.971 43.1353 51.1902 43.6914 51.5844 44.1137C52.0156 44.5758 52.6194 44.8382 53.2515 44.8382H53.6421V45.4829H40.6552V44.8382H41.152C41.7775 44.8382 42.3774 44.5897 42.8197 44.1474C43.262 43.7051 43.5103 43.112 43.5114 42.4865C43.5363 28.7416 44.0665 3.30026 44.0665 2.50875Z"
        fill="#EFEFEF"
      />
    </svg>
  );
};
export const Logo = () => {
  return (
    <div className="logo">
      <LogoIcon />
      <p id="name">GIULIANA RICO</p>
    </div>
  );
};
