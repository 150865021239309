import React from "react";
import "./index.css";
import pinterestImage from "./assets/Pinterest.png";
import instagramImage from "./assets/Instagram.png";
import backgroundImage from "./assets/bg-logo.svg";
import { Logo, LogoIcon } from "./components/Logo";

function PageGR() {
  return (
    <main className="page">
      <header>
        <Logo />
      </header>

      <section className="hero">
        <div className="hero-title">
          <h1 className="title">PRÓXIMAMENTE</h1>
          <h2 className="subtitle">¡La tendencia del futuro te espera! </h2>
        </div>
        <div className="form">
          <p>
            ¡Déjanos tu <b>E-Mail</b> para informarte cuando el sitio esté
            terminado!
          </p>
          <div className="contact">
            <form action="submit.php" method="post">
              <input
                type="text"
                name="email"
                className="email"
                placeholder="E-Mail"
              />
              <input type="submit" className="submit-button" value="ENVIAR" />
            </form>
          </div>
        </div>
      </section>
      <footer className="social-media">
        <img src={pinterestImage} alt="pinterest" id="pinterest" />
        <img src={instagramImage} alt="Instagram" id="instagram" />
      </footer>

      <div className="background-logo">
        <img src={backgroundImage} alt="bg-logo" />
      </div>
    </main>
  );
}

export default PageGR;
